import api from "../api"

const initChat = async (chatId, mode) => {
    let postData = {};
    return await api.post(`/UserLiveChat/${chatId}/Init`, postData);
};

const getMessages = async (chatId) => {
    return await api.get(`/UserLiveChat/${chatId}/Messages`);
};


export default { initChat, getMessages };