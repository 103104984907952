import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Crisp } from "crisp-sdk-web";
import chatApi from '../services/general/chat';

// 創建 Crisp Context
const LiveChatContext = createContext(null);

export const useLiveChat = () => useContext(LiveChatContext);

export const LiveChatProvider = ({ children }) => {
    const [sessionId, setSessionId] = useState("");
    const [unreadAmount, setUnreadAmount] = useState(0);
    const [messages, setMessages] = useState([]);
    const [isChatReady, setIsChatReady] = useState(false);
    const userStore = useSelector(state => state.user);

    const initChat = async (sessionId) => {
        await chatApi.initChat(sessionId);
        await chatApi.getMessages(sessionId)
            .then((response) => {
                setMessages(response.data.data);
                setIsChatReady(true);
            });
    };
    const openChat = async ({ mode = "default", targetId = null } = {}) => {
        if (!userStore?.userId) return;
        let tokeId = userStore?.userId;
        if (mode) {
            switch (mode) {
                case "question":
                    tokeId = `${userStore?.userId}-Q-${targetId}`;
                    break;
            }
        }
        if (Crisp.tokenId !== tokeId) {
            resetChat(tokeId);
        }
        if (userStore?.user?.email !== null)
            Crisp.user.setEmail(userStore?.user?.email);
        Crisp.user.setNickname(userStore?.user?.name);
        Crisp.load();
        Crisp.chat.open();
        Crisp.chat.hide();
    };

    const closeChat = () => {
        if (Crisp) {
            Crisp.chat.close();
        }
    };
    const markAsAllRead = () => {
        Crisp.message.markAsRead();
        setUnreadAmount(0);
    };


    const resetChat = (tokeId) => {
        if (Crisp) {
            Crisp.setTokenId(tokeId); // 1. Clear the token value
            Crisp.session.reset(); // 2. Unbind the current session
            setSessionId(null);
            setMessages([]);
        }
    }
    // 定義一個函數來更新未讀訊息數量
    const updateUnreadCount = () => {
        const count = Crisp.chat.unreadCount();
        setUnreadAmount(count);
    };
    const pushEvent = (eventName, data) => {
        if (Crisp) {
            Crisp.session.pushEvent(eventName, data?.id);
            switch (eventName) {
                case "question":
                    Crisp.session.setData({ questionId: `${data?.id}` });
                    break;

            }
        }
    }
    const setLocalMessage = (inputMessage) => {
        if (inputMessage) {
            switch (inputMessage.type) {
                case "text":
                case "helpChoice":
                    setMessages([...messages, inputMessage]);
                    break;
            }
        }
    };

    const sendMessage = (inputMessage) => {
        if (inputMessage) {
            switch (inputMessage.type) {
                case "text":
                    Crisp.message.send("text", inputMessage.text);
                    setMessages(prevMessages => [...prevMessages, { from: 'user', text: inputMessage.text }]);
                    break;
            }
        }
    };
    useEffect(() => {
        const CRISP_WEBSITE_ID = "da50c369-76ec-45d6-89c0-f383baa48c64";

        if (!Crisp) return;

        Crisp.configure(CRISP_WEBSITE_ID, {
            autoload: false,
        });

        Crisp.session.onLoaded((sessionId) => {
            setSessionId(sessionId);
            updateUnreadCount();
        });

        Crisp.message.onMessageReceived((data) => {
            setMessages(prevMessages => [...prevMessages, { from: data.from, type: data.type, text: data.content }]);
            updateUnreadCount();
        });

    }, [Crisp])

    useEffect(() => {
        if (!Crisp) return;

        if (!userStore?.userId) {
            resetChat();
            return;
        }
        else if (Crisp.tokenId !== userStore?.userId) {
            resetChat(userStore?.userId);
        }
        // if (userStore?.user?.email !== null)
        //     Crisp.user.setEmail(userStore?.user?.email);
        Crisp.user.setNickname(userStore?.user?.name);

        Crisp.load();
        Crisp.chat.hide();

    }, [userStore?.userId, Crisp]);

    useEffect(() => {
        if (Crisp && sessionId && userStore?.isAuthed) {
            setIsChatReady(false);
            initChat(sessionId);
        }
    }, [sessionId])

    return (
        <LiveChatContext.Provider value={{ sessionId, messages, isChatReady, sendMessage, pushEvent, unreadAmount, markAsAllRead, setLocalMessage, openChat, closeChat }}>
            {children}
        </LiveChatContext.Provider>
    );
};